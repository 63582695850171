/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Input,
  Label,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  Badge
} from "reactstrap"
import { ReactMultiEmail } from "react-multi-email"
import { AvField, AvForm } from "availity-reactstrap-validation"
import "react-multi-email/dist/style.css"
import MultiSelect from "react-multiple-select-dropdown-lite"
import { postArea } from "helpers/LaundryHelper"
import toastr from "toastr"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Dropdown } from "react-bootstrap"
import customerIcon from '../../assets/images/customer_icon.png';
import driverIcon from '../../assets/images/driver_icon.png';
import posIcon from '../../assets/images/pos_icon.png';
import Switch from "react-switch"
import moment from "moment"


const SettingRow = ({ setting, onAction ,activeTab, userData, updatePermission }) => {
  switch (setting.type) {
    case "androidVersion":
    case "iosVersion":
    case "androidAdminVersion":
    case "androidPosVersion":
    case "androidDriverVersion":
      return <VersionSettingRow setting={setting} onAction={onAction} activeTab={activeTab}  userData={userData} updatePermission={updatePermission}/>

    case "sms":
      return <SmsSettingRow setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>

    case "logisticEmails":
      return <LogisticEmailsSettingRow setting={setting} onAction={onAction} activeTab={activeTab}  updatePermission={updatePermission}/>

    case "joining_bonus_image":
      return (
        <JoiningBonusImageSettingRow setting={setting} onAction={onAction} activeTab={activeTab}  updatePermission={updatePermission}/>
      )
    case "orderAutoAssign":
      return <OrderAutoAssignRow setting={setting} onAction={onAction}  activeTab={activeTab} updatePermission={updatePermission}/>
    
    case "defaultOTP":
      return <DefaultOTP setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>

    case "careersWhatsApp":
      return <CareerWhatsApp setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>

    case "onlinePackagePromo":
      return <OnlinePackagePromo setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>

    case "onlinePackageBanner":
      return (
        <OnlinePackageBanner setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>
      )

    case "mobilePrefix":
      return <MobilePrefix setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>

    case "carpetClothes":
      return <CarpetClothes setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>
    
    case "orderTerms":
      return <OrderTerms setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>
    
    case "loyaltyPointsConfig":
      return <LoyaltyPointsSettingRow setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>
    
    case "updatePricelistEnabled":
      return <PriceListEnable setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>

    case "pausedServices":
      return <ServiceToggle title="Pause Customer App Services" setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>
      
    case "freeMoneyPromoCode":
      return <FreeMoneyPromoCode setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>

    case "deliveryFeeLaunchDate":
      return <DeliveryFeeLaunchDate setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>

    case "showCustomerAppDiscount":
      return <ShowCustomerAppDiscount setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>

    case "cashback":
      return <Cashback setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>

    case "whatsAppSupport":
      return <WhatsAppNumber setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>

    case "orderPickupHours":
      return <OrderPickupHours setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>
    
    case "KleenDeqaDriverBranchId": 
      return <KleenDeqaDriverBranchId setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>
    default:
      return <DefaultSettingRow setting={setting} onAction={onAction} activeTab={activeTab} updatePermission={updatePermission}/>
  }
}

const MobilePrefix = ({ setting, onAction ,activeTab, updatePermission}) => {
  
  const [val, setVal] = React.useState("")
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");
  const handleSelect = (value) => {
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: {
        mobilePrefix: val,
      },
      category:value
    })
  }


  useEffect(() => {
    let _mobilePrefix = setting?.objValue?.mobilePrefix;
    setVal(_mobilePrefix); 
    // let defaultValue = "";
    // if(_mobilePrefix && _mobilePrefix.length > 0){
    //   defaultValue = _mobilePrefix.join(',');
      
    // }
    // setVal(defaultValue);
  }, [setting?.objValue?.mobilePrefix])
  
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{setting.type}</CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">
            Mobile prefix allowed
          </Label>
          <Col sm={6}>
            <ReactMultiEmail
              placeholder="Mobile Prefix like 9665, 91"
              emails={val || []}
              validateEmail={() => true}
              onChange={_emails => {
                console.log(_emails);
                setVal(_emails)
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => {
                      if(updatePermission){
                        removeEmail(index)
                      }
                    }}>
                      ×
                    </span>
                  </div>
                )
              }}
            />  
            
            <span>Enter multiple prefix separed by comma ex. 9665, 91</span>
          </Col>
          {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  onAction({
                    type: setting.type,
                    objValue: {
                      mobilePrefix: val,
                    },
                    category:selectedValue
                  })
                }}
              >
                Save
              </Button>
            </Col>
            : 
            null
          }
        </div>
        <div className="row align-items-center mt-4">
        <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
        {
          updatePermission ?
          <Col sm={6}>
          <Dropdown onSelect={handleSelect} direction={"down"}>
      <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
           {selectedValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="System">System</Dropdown.Item>
        <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </Col>
          :
          null
        }
        </div>
      </CardBody>

    </Card>
  )
}

const KleenDeqaDriverBranchId = ({ setting, onAction, activeTab, updatePermission }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Kleen Driver - Deqa Branch Id
            <br/>
            <span className="text-danger font-size-10">(Note: This is a read-only field as of now.)</span>
        </CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">
            Branch Id
          </Label>
          <Col sm={6}>
            <Input
              // disabled={!updatePermission}
              disabled
              type="text"
              value={setting?.objValue?.ID}
              onChange={e => {
                onAction({
                  type: setting.type,
                  objValue: {
                    ID: e.target.value
                  }
                })
              }}
            />
          </Col>
          {/* {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  onAction({
                    type: setting.type,
                    objValue: {
                      branchId: setting?.objValue?.branchId
                    }
                  })
                }}
              >
                Save
              </Button>
            </Col>
            :
            null
          } */}
        </div>
        {/* Add two labels and two input fields for latitude and longitude */}
        <div
          // add column classes to the div
          className="col align-items-center mt-4"
        >
          <div className="row align-items-center">
            <Label className="col-sm-2 col-form-label">
              Latitude
            </Label>
            <Col sm={3}>
              <Input
                // disabled={!updatePermission}
                disabled
                type="text"
                value={setting?.objValue?.latitude}
                onChange={e => {
                  onAction({
                    type: setting.type,
                    objValue: {
                      latitude: e.target.value
                    }
                  })
                }}
              />
            </Col>
          </div>
          <div className="row align-items-center">
            <Label className="col-sm-2 col-form-label">
              Longitude
            </Label>
            <Col sm={3}>
              <Input
                // disabled={!updatePermission}
                disabled
                type="text"
                value={setting?.objValue?.longitude}
                onChange={e => {
                  onAction({
                    type: setting.type,
                    objValue: {
                      longitude: e.target.value
                    }
                  })
                }}
              />
            </Col>

          </div>
        </div>
      </CardBody>
    </Card>
  )
}

const OrderTerms = ({ setting, onAction ,activeTab, updatePermission }) => {
  
  const [val, setVal] = React.useState("")
  const [showTerms, setShowTerms] = React.useState(false)
  const [showTermsAr, setShowTermsAr] = React.useState(false)
  const termsEditor = useRef(null);
  const termsEditorAr = useRef(null);

  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");

  const handleSelect = (value) => {
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: {...setting?.objValue},
      category:value
    })
  }

  const toggle = () => setShowTerms(!showTerms)
  const toggleAr = () => setShowTermsAr(!showTermsAr)
  
  return (
    <>
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Order Terms &amp; Conditions</CardTitle>
        <div className="d-flex align-items-center">
          {
            updatePermission ?
            <>
              <Button 
                className="me-2"
                onClick={() => setShowTerms(true)}
                color="success"> <i className="bx bx-edit-alt"></i> English</Button>
            
              <Button 
                onClick={() => setShowTermsAr(true)}
                color="success"> <i className="bx bx-edit-alt"></i> Arabic</Button>
            </>
            :
            null
          }
        </div>
        <div className="row align-items-center mt-4">
       <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
       {
          updatePermission ?
          <Col sm={6}>
          <Dropdown onSelect={handleSelect} direction={"down"}>
      <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
           {selectedValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="System">System</Dropdown.Item>
        <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </Col>
          : 
          null
       }
        </div>
      </CardBody>
    </Card>
    <Modal isOpen={showTerms} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
      Terms & Conditions - English
      </ModalHeader>
      <ModalBody>
          <Row className="d-flex">
          <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [ 'bold', 'italic','link', '|', 
                'heading', 'bulletedList', 'numberedList', 'undo', 'redo' ]
              }}
              data={""}
              onReady={editor => {
                termsEditor.current = editor;
                if(setting?.objValue?.enOrderTerms)
                  editor.setData(setting?.objValue?.enOrderTerms)
              }}
              onChange={(event, editor) => {
                
                
              }}
              
            />
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <Button 
                  onClick={toggle}
                  color="secondary"
                  className="me-2"
                  > Close</Button>

                <button
                  type="submit"
                  onClick={() => {
                    onAction({
                      type: setting.type,
                      objValue: {...setting?.objValue, 
                        enOrderTerms: termsEditor?.current?.getData()
                      },
                      category:selectedValue
                    })
                  }}
                  className="btn btn-success save-user"
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        
      </ModalBody>
    </Modal>

    <Modal isOpen={showTermsAr} toggle={toggleAr} size="lg">
      <ModalHeader toggle={toggleAr} tag="h4">
        {"Terms & Conditions - Arabic"}
      </ModalHeader>
      <ModalBody>
          <Row className="d-flex">
          <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [ 'bold', 'italic','link', '|', 
                'heading', 'bulletedList', 'numberedList', 'undo', 'redo' ],
                language : "ar"
              }}
              data={""}
              onReady={editor => {
                termsEditorAr.current = editor;
                if(setting?.objValue?.arOrderTerms)
                  editor.setData(setting?.objValue?.arOrderTerms)
              }}
              onChange={(event, editor) => {
                
                
              }}
              
            />
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <Button 
                  onClick={toggleAr}
                  color="secondary"
                  className="me-2"
                  > Close</Button>
                <button
                  type="submit"
                  onClick={() => {
                    onAction({
                      type: setting.type,
                      objValue: {...setting?.objValue, 
                        arOrderTerms: termsEditorAr?.current?.getData()
                      },
                      category:selectedValue
                    })
                  }}
                  className="btn btn-success save-user"
                >
                  Save
                </button>
              </div>
            </Col>
          </Row>
        
      </ModalBody>
    </Modal>

    
    </>
  )
}


const CarpetClothes = ({ setting, onAction, activeTab, updatePermission }) => {
  
  const [val, setVal] = React.useState("")
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");

  const handleSelect = (value) =>{
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: {
        carpetClothes: val,
      },
      category:value
    })

  }

  useEffect(() => {
    let _carpetClothes = setting?.objValue?.carpetClothes;
    
    setVal(_carpetClothes);
  }, [setting?.objValue?.carpetClothes])
  
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{setting.type}</CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">
            Carpet Ids
          </Label>
          <Col sm={6}>
            <ReactMultiEmail
              placeholder="Carpet Id seprated by comma"
              emails={val || []}
              validateEmail={() => true}
              onChange={_emails => {
                setVal(_emails)
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => {
                      if(updatePermission){
                        removeEmail(index)
                      }
                    }}>
                      ×
                    </span>
                  </div>
                )
              }}
            />
            <span>Enter multiple carpet ID separed by comma ex. ID1, ID2</span>
          </Col>
          {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  onAction({
                    type: setting.type,
                    objValue: {
                      carpetClothes: val,
                    },
                    category:selectedValue
                  })
                }}
              >
                Save
              </Button>
            </Col>
            :
            null
          }
        </div>
        <div className="row align-items-center mt-4">
        <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
        {
          updatePermission ?
          <Col sm={6}>
          <Dropdown onSelect={handleSelect} direction={"down"}>
      <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
           {selectedValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="System">System</Dropdown.Item>
        <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </Col>
          :
          null
        }
        </div>
      </CardBody>
    </Card>
  )
}

const OnlinePackagePromo = ({ setting, onAction, activeTab, updatePermission }) => {
  const [val, setVal] = React.useState(setting?.objValue?.onlinePackagePromo)
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");

  const handleSelect = (value) =>{
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: {
        onlinePackagePromo: val,
      },
      category:value
    })
  }
  
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{setting.type}</CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">
            Online Package 100% Promocode
          </Label>
          <Col sm={6}>
            <Input
              disabled={!updatePermission}
              type="text"
              value={val}
              onChange={e => setVal(e.target.value)}
            />
          </Col>
          {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  onAction({
                    type: setting.type,
                    objValue: {
                      onlinePackagePromo: val,
                    },
                    category:selectedValue
                  })
                }}
              >
                Save
              </Button>
            </Col>
            :
            null
          }
        </div>
        <div className="row align-items-center mt-4">
        <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
         {
            updatePermission ?
            <Col sm={6}>
            <Dropdown onSelect={handleSelect} direction={"down"}>
        <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
            {selectedValue}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="System">System</Dropdown.Item>
          <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
            </Col>
            :
            null
          }
        </div>
      </CardBody>
    </Card>
  )
}

const DefaultOTP = ({ setting, onAction,activeTab, updatePermission }) => {
  const [val, setVal] = React.useState(setting?.objValue?.defaultOTP)
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");
  const handleSelect = (value) =>{ 
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: {
        defaultOTP: val
      },
      category:value
    })
  }
  
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{setting.type}</CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">
            Default Customer App OTP
          </Label>
          <Col sm={6}>
            <Input
              disabled={!updatePermission}
              type="text"
              value={val}
              onChange={e => setVal(e.target.value)}
            />
          </Col>
          {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  onAction({
                    type: setting.type,
                    objValue: {
                      defaultOTP: val
                    },
                    category:selectedValue
                  })
                }}
              >
                Save
              </Button>
            </Col>
            :
            null

          }
        </div>
        <div className="row align-items-center mt-4">
        <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
        {
          updatePermission ?
          <Col sm={6}>
          <Dropdown onSelect={handleSelect} direction={"down"}>
      <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
           {selectedValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="System">System</Dropdown.Item>
        <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </Col>
          :
          null
        }
        </div>
      </CardBody>
    </Card>
  )
}

const CareerWhatsApp = ({ setting, onAction ,activeTab, updatePermission}) => {
  const [val, setVal] = React.useState(setting?.objValue?.careersWhatsApp)
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");
  const handleSelect = (value) =>{ 
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: {
        careersWhatsApp: val,
      },
      category:value
    })
  }
  
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">WhatsApp Number for Career Page on Kleen Website</CardTitle>
         <span className="col-6 desc-color mb-2" >
            WhatsApp number which will be shown on kleen website (kleen.sa) on the careers page.
          </span>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">
           Mobile No
          </Label>
          <Col sm={6}>
            <Input
              disabled={!updatePermission}
              type="text"
              maxLength={13}
              value={val}
              onChange={e => setVal(e.target.value)}
            />
          </Col>
          {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  onAction({
                    type: setting.type,
                    objValue: {
                      careersWhatsApp: val,
                    },
                  })
                }}
              >
                Save
              </Button>
            </Col>
            : 
            null
          }
        </div>
        <div className="row align-items-center mt-4">
        <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
        {
          updatePermission ?
          <Col sm={6}>
          <Dropdown onSelect={handleSelect} direction={"down"}>
      <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
           {selectedValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="System">System</Dropdown.Item>
        <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </Col>
          :
          null
        }
        </div>
      </CardBody>
    </Card>
  )
}

const WhatsAppNumber = ({ setting, onAction, activeTab, updatePermission }) => {
  const [val, setVal] = React.useState(setting?.objValue?.supportMobile)
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");
  const handleSelect = (value) =>{ 
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: {
        supportMobile: val,
      },
      category:value
    })
  }
  
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Whatsapp Support Number for OTP</CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">
           Support Mobile No
          </Label>
          <Col sm={6}>
            <Input
              disabled={!updatePermission}
              type="number"
              maxLength={13}
              value={val}
              onChange={e => setVal(e.target.value)}
            />
          </Col>
            {
              updatePermission ?
              <Col>
                <Button
                  color="primary"
                  onClick={() => {
                    onAction({
                      type: setting.type,
                      objValue: {
                        supportMobile: val,
                      },
                    })
                  }}
                >
                  Save
                </Button>
              </Col>
              :
              null
            }
        </div>
        <div className="row align-items-center mt-4">
        <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
        {
          updatePermission ?
          <Col sm={6}>
          <Dropdown onSelect={handleSelect} direction={"down"}>
      <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
           {selectedValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="System">System</Dropdown.Item>
        <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </Col>
          :
          null
        }
        </div>
      </CardBody>
    </Card>
  )
}

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

const OrderPickupHours = ({ setting, onAction, activeTab, updatePermission }) => {
  const [timeRange, setTimeRange] = useState({
    startTime: setting?.objValue?.startTime || '',
    endTime: setting?.objValue?.endTime || ''
  });
  const [displayTimeRange, setDisplayTimeRange] = useState({
    startTime: '',
    endTime: ''
  });
  const [selectedValue, setSelectedValue] = useState(setting?.category || "System");

  // Convert 24-hour time to 12-hour format for display
  useEffect(() => {
    const formattedStartTime = moment(timeRange.startTime, 'HH:mm').format('hh:mm A');
    const formattedEndTime = moment(timeRange.endTime, 'HH:mm').format('hh:mm A');
    setDisplayTimeRange({
      startTime: formattedStartTime,
      endTime: formattedEndTime
    });
  }, [timeRange]);

  const handleSelect = (value) => {
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: timeRange,
      category: value
    });
  };

  const handleTimeChange = (key, value) => {
    // Convert the input time value to 24-hour format using Moment.js
    const formattedTime = moment(value, 'hh:mm A').format('HH:mm');
    setTimeRange(prevState => ({
      ...prevState,
      [key]: formattedTime
    }));
  };

  const handleSave = () => {
    onAction({
      type: setting.type,
      objValue: timeRange,
      category: selectedValue
    });
  };
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Order Pickup Hours</CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">Start Time</Label>
          <Col sm={2}>
            <Input
              disabled={!updatePermission}
              type="time"
              value={timeRange.startTime}
              onChange={e => handleTimeChange('startTime', e.target.value)}
            />
          </Col>
          <Label className="col-sm-2 col-form-label">End Time</Label>
          <Col sm={2}>
            <Input
              disabled={!updatePermission}
              type="time"
              value={timeRange.endTime}
              onChange={e => handleTimeChange('endTime', e.target.value)}
            />
          </Col>
          {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={handleSave}
              >
                Save
              </Button>
            </Col>
            :
            null
          }
        </div>
        <div className="row align-items-center mt-4">
          <Label className="col-sm-2 col-form-label">Category : {activeTab == "1" ? "System" : "Operation"}</Label>
          {
            updatePermission ?
            <Col sm={6}>
              <Dropdown onSelect={handleSelect} direction={"down"}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
                  {selectedValue}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="System">System</Dropdown.Item>
                  <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            :
            null
          }
        </div>
      </CardBody>
    </Card>
  );
};


//Delivery fee launch date
const DeliveryFeeLaunchDate = ({ setting, onAction, activeTab, updatePermission }) => {
  const [val, setVal] = useState(setting?.objValue || "");
  const [selectedValue, setSelectedValue] = useState(setting?.category || "System");

  const handleSelect = (value) => {
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: val,
      category: value
    });
  };

  const handleSave = () => {
    onAction({
      type: setting.type,
      objValue: val,
      category: selectedValue
    });
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Delivery Fee Launch Date</CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">Launch Date</Label>
          <Col sm={6}>
            <Input
              disabled={!updatePermission}
              type="date"
              value={val}
              onChange={e => setVal(e.target.value)}
            />
          </Col>
          {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={handleSave}
              >
                Save
              </Button>
            </Col>
            : 
            null
          }
        </div>
        <div className="row align-items-center mt-4">
          <Label className="col-sm-2 col-form-label">Category: {activeTab === "1" ? "System" : "Operation"}</Label>
          {
            updatePermission ?
            <Col sm={6}>
              <Dropdown onSelect={handleSelect} direction={"down"}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
                  {selectedValue}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="System">System</Dropdown.Item>
                  <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            :
            null
          }
        </div>
      </CardBody>
    </Card>
  );
};

//toggling clothes and carpet service individually in single component:
const ServiceToggle = ({ setting, onAction, title, activeTab, updatePermission }) => {
  const [val, setVal] = useState({
      cloth: {
        paused: false,
        messageEn: "",
        messageAr: "",
      },
      carpet: {
        paused: false,
        messageEn: "",
        messageAr: "",
      },
    }
  );

  const [selectedValue, setSelectedValue] = useState(
    setting?.category || "System"
  );
  const [messageEnCloth, setMessageEnCloth] = useState("");
  const [messageArCloth, setMessageArCloth] = useState("");
  const [messageEnCarpet, setMessageEnCarpet] = useState("");
  const [messageArCarpet, setMessageArCarpet] = useState("");

  useEffect(() => {
    setVal(setting?.objValue)
    setMessageEnCloth(setting?.objValue?.cloth?.messageEn)
    setMessageArCloth(setting?.objValue?.cloth?.messageAr)
    setMessageEnCarpet(setting?.objValue?.carpet?.messageEn)
    setMessageArCarpet(setting?.objValue?.carpet?.messageAr)
  }, [setting?.objValue])

  const handleSelect = (value) => {
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: val,
      category: value,
    });
  };

  const isFormValid = () => {
    if (val?.cloth?.paused) {
      if (!messageEnCloth || !messageArCloth) {
        return false;
      }
    }
    if (val?.carpet?.paused) {
      if (!messageEnCarpet || !messageArCarpet) {
        return false;
      }
    }
    return true;
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{title}</CardTitle>
        <div className="row align-items-center ml-0">
          <Col sm={6}>
            <div className="d-flex align-items-center gap-2">
              <label className="form-check-label fw-bold">
                Pause Clothes Service.
              </label>
              <Switch
                disabled={!updatePermission}
                checked={val?.cloth?.paused || false}
                onChange={() => {
                  setVal((prevVal) => ({
                    ...prevVal,
                    cloth: {
                      ...prevVal?.cloth || val?.cloth,
                      paused: !prevVal?.cloth?.paused || false,
                    },
                  }));
                }}
                onColor="#dc3545"
                checkedIcon={<OnSymbol />}
                uncheckedIcon={<Offsymbol />}
              />
            </div>
            {val?.cloth?.paused ? (
              <div>
                <Input
                  disabled={!updatePermission}
                  name="messageEnClothes"
                  className="mt-2"
                  type="text"
                  placeholder="Enter the Message in English."
                  value={messageEnCloth}
                  onChange={(e) => setMessageEnCloth(e.target.value)}
                />
                {!messageEnCloth && (
                  <p style={{ color: "red" }}>Please Enter a Reason.</p>
                )}

                <Input
                  disabled={!updatePermission}
                  name="messageArClothes"
                  className="mt-2"
                  type="text"
                  placeholder="أدخل الرسالة باللغة العربية (Message in Arabic)."
                  value={messageArCloth}
                  onChange={(e) => setMessageArCloth(e.target.value)}
                />
                {!messageArCloth && (
                  <p style={{ color: "red" }}>الرجاء إدخال السبب. (Please Enter a Reason)</p>
                )}
              </div>
            ):null}
            <hr />
            <div className="mt-2 d-flex align-items-center gap-2">
              <label className="form-check-label fw-bold">
                Pause Carpet Service.
              </label>
              <Switch
                disabled={!updatePermission}
                checked={val?.carpet?.paused || false}
                onChange={() => {
                  setVal((prevVal) => ({
                    ...prevVal,
                    carpet: {
                      ...prevVal?.carpet || val?.carpet,
                      paused: !prevVal?.carpet?.paused,
                    },
                  }));
                }}
                onColor="#dc3545"
                checkedIcon={<OnSymbol />}
                uncheckedIcon={<Offsymbol />}
              />
            </div>
            {val?.carpet?.paused ? (
              <div>
                <Input
                  disabled={!updatePermission}
                  name="messageEnCarpet"
                  className="mt-2"
                  type="text"
                  placeholder="Enter the Message in English."
                  value={messageEnCarpet}
                  onChange={(e) => setMessageEnCarpet(e.target.value)}
                />
                {!messageEnCarpet && (
                  <p style={{ color: "red" }}>Please Enter a Reason.</p>
                )}

                <Input
                  disabled={!updatePermission}
                  name="messageArCarpet"
                  className="mt-2"
                  type="text"
                  placeholder="أدخل الرسالة باللغة العربية (Message in Arabic)."
                  value={messageArCarpet}
                  onChange={(e) => setMessageArCarpet(e.target.value)}
                />
                {!messageArCarpet && (
                  <p style={{ color: "red" }}>الرجاء إدخال السبب. (Please Enter a Reason.)</p>
                )}
              </div>
            ):null}
          </Col>
        </div>
        <div className="row align-items-center mt-4">
          <Col sm={6}>
            <div className="row">
              <Label className="col-sm-12 col-form-label">
                Category : {activeTab == "1" ? "System" : "Operation "}
              </Label>
              {
                updatePermission ?
                <Col sm={12}>
                  <Dropdown onSelect={handleSelect} direction={"down"}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
                      {selectedValue}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="System">System</Dropdown.Item>
                      <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                :
                null
              }
            </div>
          </Col>
          {
            updatePermission ?
            <Col sm={6}>
              <Button
                color="primary"
                disabled={!isFormValid()}
                onClick={() => {
                  onAction({
                    type: setting.type,
                    objValue: {
                      ...val,
                      cloth: {
                        ...val.cloth,
                        messageEn: val.cloth.paused ? messageEnCloth : val.cloth.messageEn,
                        messageAr: val.cloth.paused ? messageArCloth : val.cloth.messageAr
                      },
                      carpet: {
                        ...val.carpet,
                        messageEn: val.carpet.paused ? messageEnCarpet : val.carpet.messageEn,
                        messageAr: val.carpet.paused ? messageArCarpet : val.carpet.messageAr
                      }
                    },
                    category: selectedValue
                  })
                }}
              >
                Save
              </Button>
            </Col>
            :
            null
          }
        </div>
      </CardBody>
    </Card>
  );
};

//component to store defaullt Proomo code for free money
const FreeMoneyPromoCode = ({ setting, onAction, activeTab, updatePermission }) => {
  const [val, setVal] = useState("");
  const [selectedValue, setSelectedValue] = useState(setting?.category || "System");

  const handleSelect = (value) => {
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: val,
      category: value
    });
  }

  const handleSave = () => {
    onAction({
      type: setting.type,
      objValue: {
        freeMoneyPromoCode: val
      },
      category: selectedValue
    });
  }

  useEffect(() => {
    setVal(setting?.objValue?.freeMoneyPromoCode);
  }, [setting?.objValue?.freeMoneyPromoCode]);

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Free Money</CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">Promo Code</Label>
          <Col sm={6}>
            <Input
              disabled={!updatePermission}
              type="text"
              value={val}
              onChange={e => setVal(e.target.value)}
            />
          </Col>
          {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={handleSave}
              >
                Save
              </Button>
            </Col>
            :
            null
          }
        </div>
        <div className="row align-items-center mt-4">
          <Label className="col-sm-2 col-form-label">Category: {activeTab === "1" ? "System" : "Operation"}</Label>
          {
            updatePermission ?
            <Col sm={6}>
              <Dropdown onSelect={handleSelect} direction={"down"}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
                  {selectedValue}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="System">System</Dropdown.Item>
                  <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col> 
            :
            null
          }
        </div>
      </CardBody>
    </Card>
  );
}

const PriceListEnable = ({ setting, onAction, activeTab, updatePermission }) => {

  const [val, setVal] = React.useState(setting?.value == 1 ? true : false  || false) 
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");

  const handleSelect = (value) => {
    setSelectedValue(value);
    onAction({
      type: setting.type,
      value:val,
      category:value
    })
  }
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Update Price List</CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">
          Update Price List Enabled?
          </Label>
          <Col sm={6}>
        
         <div>
          <div className="form-check form-switch">
           <input className="form-check-input w-9" type="checkbox" disabled={!updatePermission} checked={val} role="switch" style={{
           width: "43px",
           height: "22px"
}}         onClick={() => {
            setVal(!val);
          }}/>
        </div>
         </div>
          </Col>
          {
            updatePermission ?
            <Col className="mt-4">
              <Button
                color="primary"
                onClick={() => {
                  onAction({
                    type: setting.type,
                    value:val,
                    category:selectedValue
                  })
                }}
              >
                Save
              </Button>
            </Col>
            :
            null
          }
        </div>
        <div className="row align-items-center">
        <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
          {
            updatePermission ? 
            <Col sm={6}>
            <Dropdown onSelect={handleSelect} direction={"down"}>
        <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
            {selectedValue}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="System">System</Dropdown.Item>
          <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
            </Col>
            :
            null
          }
        </div>
      </CardBody>
    </Card>
  )
}
const ShowCustomerAppDiscount = ({ setting, onAction, activeTab, updatePermission }) => {
  const [val, setVal] = React.useState(setting?.value == 1 ? true : false  || false)
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");

  const handleSelect = (value) =>{
    setSelectedValue(value); 
    onAction({
      type: setting.type,
      value:val,
      category:value
    })
  }
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Customer App Discount in POS</CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label" style={{width:"fit-content"}}>
          Show customer app discount in POS App 
          </Label>
          <Col sm={5}  >
        
         <div>
          <div className="form-check form-switch" >
           <input className="form-check-input w-9" type="checkbox" disabled={!updatePermission} checked={val} role="switch" style={{
           width: "43px",
           height: "22px"
}}         onClick={() => {
            setVal(!val);
          }}/>
        </div>
         </div>
          </Col>
          {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  onAction({
                    type: setting.type,
                    value:val,
                    category:selectedValue
                  })
                }}
              >
                Save
              </Button>
            </Col>
            :
            null
          }
        </div>
        <div className="row align-items-center mt-4">
        <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
        {
          updatePermission ?
          <Col sm={6}>
          <Dropdown onSelect={handleSelect} direction={"down"}>
      <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
           {selectedValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="System">System</Dropdown.Item>
        <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </Col>
          :
          null
        }
        </div>
      </CardBody>
    </Card>
  )
}
const Cashback = ({ setting, onAction, activeTab, updatePermission }) => {
  const [val, setVal] = React.useState(setting?.value == 1 ? true : false  || false)
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");

  const handleSelect = (value) =>{
    setSelectedValue(value); 
    onAction({
      type: setting.type,
      value:val,
      category:value
    })
  }
  return (
    <Card>
      <CardBody>
        <CardTitle>Cashback</CardTitle>
        <div className="row">
          <span className="col-6 desc-color" >
          If cashback is enabled then only gifts and loyalty point will be treated as free money, otherwise it will go inside real money (wallet)
          </span>
          </div>
        <div className="row align-items-center mt-2">
          <Label className="col-sm-2 col-form-label" style={{width:"fit-content"}}>
           Cashback Enabled?
          </Label>
          <Col sm={5}  >
         <div>
          <div className="form-check form-switch" >
           <input className="form-check-input w-9" type="checkbox" disabled={!updatePermission}  checked={val} role="switch" style={{
           width: "43px",
           height: "22px"
}}         onClick={() => {
            setVal(!val);
          }}/>
        </div>
         </div>
          </Col>
          {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  onAction({
                    type: setting.type,
                    value:val,
                    category:selectedValue
                  })
                }}
              >
                Save
              </Button>
            </Col>
            :
            null
          }
        </div>
        <div className="row align-items-center mt-4">
        <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
        {
          updatePermission ?
          <Col sm={6}>
          <Dropdown onSelect={handleSelect} direction={"down"}>
      <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
           {selectedValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="System">System</Dropdown.Item>
        <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </Col>
          :
          null
        }   
        </div>
      </CardBody>
    </Card>
  )
}


const OrderAutoAssignRow = ({ setting, onAction ,activeTab, updatePermission}) => {
  const [val, setVal] = React.useState([])
  const [areaOption, setAreaOption] = React.useState([])
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");
  const handleSelect = (value) =>{
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: {
        enabledAreas: val,
      },
      category:value
    })
  }
  React.useEffect(async () => {
    const AreaResponse = await postArea({})
    setAreaOption(
      AreaResponse?.record?.map(item => {
        return { label: item?.name, value: item?._id }
      })
    )
  }, [])

  React.useEffect(() => {
    const enabledAreas = setting.objValue?.enabledAreas || []
    const selectedAreas = areaOption.filter(area => enabledAreas.includes(area.value))
    setVal(selectedAreas.map(area => area.value))
  }, [areaOption])

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{setting.type}</CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">
            objValue.enabledAreas
          </Label>
          <Col sm={6}>
            <MultiSelect
              disabled={!updatePermission}
              defaultValue={val.toString()}
              className="multi-selects form-selects2 form-controlss areas-box"
              onChange={val => setVal(val ? val.split(",") : [])}
              options={areaOption}
            />
          </Col>
          {
            updatePermission ?
              <Col>
                <Button
                  color="primary"
                  onClick={() => {
                    onAction({
                      type: setting.type,
                      objValue: {
                        enabledAreas: val,
                      },
                      category:selectedValue
                    })
                  }}
                >
                  Save
                </Button>
              </Col>
              :
              null
          }
        </div>
        <div className="row align-items-center mt-4">
        <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
        {
          updatePermission ?
            <Col sm={6}>
            <Dropdown onSelect={handleSelect} direction={"down"}>
        <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
            {selectedValue}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="System">System</Dropdown.Item>
          <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
            </Col>
            :
            null
        }
        </div>
      </CardBody>
    </Card>
  )
}

const VersionSettingRow = ({ setting, onAction,activeTab ,userData, updatePermission}) => {
  const [val, setVal] = React.useState(setting.type == "androidPosVersion" ? setting?.objValue?.version : setting.value)
  // const [val2, setVal2] = React.useState(setting?.objValue?.version)
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");
  const handleSelect = (value) => {
    setSelectedValue(value);
    onAction({
      type: setting.type,
      value: val,
      objValue: {
        version: val
      },
      category:value
    })
  }
  return (
    <Card>
      <CardBody>
        <CardTitle>
          <div className="d-flex">
          <img 
          src={setting.type == "androidVersion" || setting.type == "iosVersion"? customerIcon : setting.type == "androidDriverVersion" || setting.type == "androidAdminVersion" ?
           driverIcon : setting.type == "androidPosVersion" ? posIcon : ""} style={{width:"22px",height:"22px",marginRight:"5px"}}/> 
           {setting.type == "androidDriverVersion" ? "Android Captain Version for Force Update" : setting.type == "androidAdminVersion" ? "Android Admin Version for Force Update":setting.type == "androidPosVersion" ? "Android Pos Version for Force Update":setting?.type == "androidVersion" ? "Android Customer App" :setting.type == "iosVersion" ? "Ios Customer App":null} 
            &nbsp; <span className="text-warning"> ({setting.type}) </span>
           </div>
           
           </CardTitle>
           <span className="text-success  font-weight-bold">This { setting.type == "androidVersion" || setting.type == "iosVersion" || setting.type == "androidDriverVersion" ? <>App use <span className="text-danger">value</span> field from Setting Collection</> : setting.type == "androidAdminVersion" || setting.type == "androidPosVersion" ? <>App use <span className="text-danger">objValue.version</span> field from Setting Collection</> : null}
           </span>
         
        <div className="row align-items-start mt-4">
         { setting?.type === "loyaltyPointsConfig" ? <Label className="col-sm-2 col-form-label">Points for 1 SAR spent</Label> :<Label className="col-sm-2 col-form-label">Version Name</Label>} 
          <Col sm={6}>
            <div>
            <Input
              type="number"
              className={`form-control no-spinner `}
              placeholder="numberic form of version name"
              value={val}
              disabled={!updatePermission}
              onChange={(e) => {
                let value = e.target.value;
                value = value.replace(/\D/, ''); // Remove non-numeric characters
                value = value.substring(0, 3); // Limit to 3 digits
                setVal(value);
            }}
            
            />
            </div>
            {
             <div className="mt-2">
              <span className="col-6 desc-color" >
              Update integer form of latest  app version name active on playstore. <br/>
              Ex. If the current active version name of app on playstore is <b>1.1.9</b> then enter <b>119</b>.<br/>
              it will show force update screen to all  who has app version name less then <b>1.1.9</b>
              </span>
              </div>
            }

          </Col>
          {
            updatePermission ? 
                  <Col >
                    <Button
                      color="primary"
                    
                      onClick={() => {
                        onAction({
                          type: setting.type,
                          value: val,
                          objValue: {
                            version: val
                          },
                          category:selectedValue
                        })
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                  : 
                  null
          }
        </div>
      
       
         <div className="row align-items-center mt-3">
         <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
         {
            updatePermission ?
            <Col sm={6}>
            <Dropdown onSelect={handleSelect} direction={"down"}>
        <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
              {selectedValue}
        </Dropdown.Toggle>
  
        <Dropdown.Menu>
          <Dropdown.Item eventKey="System">System</Dropdown.Item>
          <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
            </Col>
            :
            null
         }
         </div>
      </CardBody>
    </Card>
  )
}

const LoyaltyPointsSettingRow = ({ setting, onAction,activeTab, updatePermission }) => {
  const [val, setVal] = React.useState(setting?.objValue?.earnRate)
  const [val2, setVal2] = React.useState(setting?.objValue?.burnRate)
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");

   const handleSelect = (value) => {
    setSelectedValue(value);
    onAction({
      type: setting.type,
      objValue: {
        earnRate: +val,
        burnRate: +val2
      },
      category:value
    })
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>{setting.type}</CardTitle>
        <div className="row align-items-center">
         <Label className="col-sm-2 col-form-label">Points for 1 SAR spent</Label> 
          <Col sm={6}>
            <Input
              disabled={!updatePermission}
              type="number"
              className="form-control"
              placeholder="value"
              value={+val}
              onChange={e => setVal(e.target.value)}
            />
          </Col>
        </div>
        <div className="row mb-4 align-items-center">
         <Label className="col-sm-2 col-form-label">Burn 1 point for</Label> 
          <Col sm={6}>
            <Input
              type="number"
              className="form-control mt-2"
              placeholder="value"
              value={+val2}
              disabled
              onChange={e => setVal2(e.target.value)}
            />
            <span className="text-danger mt-1">Burn Point is disabled as of now </span>
          </Col>
          {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  onAction({
                    type: setting.type,
                    objValue: {
                      earnRate: +val,
                      burnRate: +val2
                    },
                    category:selectedValue
                  })
                }}
              >
                Save
              </Button>
            </Col>
            : 
            null
          }
        </div>
        <div className="row align-items-center">
       <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
        {
          updatePermission ?
          <Col sm={6}>
          <Dropdown onSelect={handleSelect} direction={"down"}>
      <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
           {selectedValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="System">System</Dropdown.Item>
        <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </Col>
          :
          null
        }
        </div>
      </CardBody>
    </Card>
  )
}

const DefaultSettingRow = ({ setting, onAction, activeTab, updatePermission }) => {
  const [val, setVal] = React.useState(setting.value)
  const [error, setError] = React.useState()
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");

  const handleSelect = (value) => {
    setSelectedValue(value)
    onAction({
      type: setting.type,
      value: val,
      category:value
    })
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{setting.type == "maxGiftDiscountPerOrder" ? " Maximum Gift Discount Per Order" : setting.type == "minRefundCombineGift" ? "Minimum Refund Balance To Combine Gift / Loyalty Balance" : setting.type }</CardTitle>
        <div className="row">
          <span className="col-6 desc-color" >
          {setting.type == "maxGiftDiscountPerOrder" ? " Define maximum gift discount can be applied to a online or offline order from customer app" : setting.type == "minRefundCombineGift" ? "Define minimum refund balance that customer can have to use Gift or Loyalty balance as a combination with refund balance on checkout screen of customer app  (Only for online order)" : null }
          </span>
          </div>
        <div className="row mb-4 align-items-center mt-4">
          <Label className="col-sm-2 col-form-label">value</Label>
          <Col sm={6}>
            <Input
              disabled={!updatePermission}
              type="number"
              className="form-control"
              id="horizontal-firstname-Input"
              placeholder="Value"
              value={val}
              onChange={e => {
                setError()
                setVal(e.target.value)
              }}
              invalid={error}
            />
           {(setting.type == "maxGiftDiscountPerOrder"  || setting.type == "minRefundCombineGift") && <span className="text-danger mt-2">If you enter 0, it means no restrictions</span>}
            <FormFeedback>{error}</FormFeedback>
          </Col>
          {
            updatePermission ?
              <Col className={(setting.type == "maxGiftDiscountPerOrder"  || setting.type == "minRefundCombineGift" ) && "mb-2"}>
                <Button
                  color="primary"
                  onClick={() => {
                    if(setting.type === "customerPriceListVAT" && val > 100) {
                      setError("customerPriceListVAT cannot be greater then 100")
                      return
                    }
                    if((setting.type === "dutyEndHours" || setting.type === "dutyStartHours") && val >= 24) {
                      setError("Hours cannot be greater then 24")
                      return
                    }
                    onAction({
                      type: setting.type,
                      value: val,
                      category:selectedValue
                    })
                  }}
                >
                  Save
                </Button>
              </Col>
              : 
              null

          }
        </div>
        <div className="row align-items-center">
       <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
          {
            updatePermission ?
            <Col sm={6}>
            <Dropdown onSelect={handleSelect} direction={"down"}>
        <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
            {selectedValue}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="System">System</Dropdown.Item>
          <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
            </Col> 
            : null
          }
        </div>
      </CardBody>
    </Card>
  )
}

const LogisticEmailsSettingRow = ({ setting, onAction, activeTab, updatePermission }) => {
  const [val, setVal] = React.useState(setting.objValue?.logisticEmails || [])
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");

  const handleSelect = (value) => {
    setSelectedValue(value)
    onAction({
      type: setting.type,
      objValue: {
        logisticEmails: val,
      },
      category:value
    })
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{setting.type}</CardTitle>
        <div className="row mb-4 align-items-center">
          <Label className="col-sm-2 col-form-label">{setting.type}</Label>
          <Col sm={6}>
            <ReactMultiEmail
              disabled={!updatePermission}
              placeholder="Logistic Emails"
              emails={val || []}
              onChange={_emails => {
                setVal(_emails)
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => {
                      if (updatePermission) {
                        removeEmail(index)
                      }
                    }}>
                      ×
                    </span>
                  </div>
                )
              }}
            />
          </Col>
          {
            updatePermission ?
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  onAction({
                    type: setting.type,
                    objValue: {
                      logisticEmails: val,
                    },
                    category:selectedValue
                  })
                }}
              >
                Save
              </Button>
            </Col>
            :
            null
          }
        </div>
        <div className="row align-items-center">
       <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
        {
          updatePermission ?
          <Col sm={6}>
          <Dropdown onSelect={handleSelect} direction={"down"}>
      <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
           {selectedValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="System">System</Dropdown.Item>
        <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </Col>
          :
          null
        }
        </div>
      </CardBody>
    </Card>
  )
}

const SmsSettingRow = ({ setting, onAction, activeTab, updatePermission }) => {
  const [val, setVal] = React.useState(setting.objValue?.provider)
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");

  const handleSelect = (value) => {
    setSelectedValue(value)
    onAction({
      type: setting.type,
      objValue: {
        provider: val,
      },
      category:value
    })
  };


  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">{setting.type}</CardTitle>
        <div className="row align-items-center">
          <Label className="col-sm-2 col-form-label">{setting.type}</Label>
          <Col sm={6}>
            <Input
              type="select"
              className="form-control"
              placeholder="Value"
              value={val}
              disabled={!updatePermission}
              onChange={e => setVal(e.target.value)}
            >
              <option value="deewan">deewan</option>
              <option value="unifonic">unifonic</option>
              <option value="smegat">smegat</option>
            </Input>
          </Col>
          {
            updatePermission ?
              <Col>
                <Button
                  color="primary"
                  onClick={() => {
                    onAction({
                      type: setting.type,
                      objValue: {
                        provider: val,
                      },
                      category:selectedValue
                    })
                  }}
                >
                  Save
                </Button>
              </Col>
              :
              null
          }
        </div>
        <div className="row align-items-center mt-3">
       <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
          {
            updatePermission ?
              <Col sm={6}>
              <Dropdown onSelect={handleSelect} direction={"down"}>
          <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
              {selectedValue}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey="System">System</Dropdown.Item>
            <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
              </Col>
              :
              null
          }
        </div>
      </CardBody>
    </Card>
  )
}

const JoiningBonusImageSettingRow = ({ setting, onAction, activeTab, updatePermission }) => {
  const [val, setVal] = React.useState(null)
  const [val2, setVal2] = React.useState('')
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");
  const handleSelect = (value) => {
    setSelectedValue(value)
    onAction({category:value}, setting._id)
  };
  const ref = useRef();
  const ref2 = useRef();
  useEffect(() => {
    ref.current.value = null;
    ref2.current.value = null;
    setVal(null);
    setVal2(null);
  }, [setting])

  return (
    <Card>
      <CardBody>
        <CardTitle>{setting.type}</CardTitle>
        <div className="row">
          <Label className="col-sm-2 col-form-label">ImageEn</Label>
          <Col sm={6}>
            <AvForm>
              <input
                disabled={!updatePermission}
                type="file"
                onChange={e => {
                  setVal(e.target.files[0])
                }}
                className="form-control"
                id="horizontal-firstname-Input"
                placeholder="Image"
                name="image"
                aria-errormessage="Please Upload Image"
                ref={ref}
              />
            </AvForm>
            {setting.objValue?.enUrl && (
              <img src={setting.objValue?.enUrl} style={{ maxWidth: "5rem" }} />
            )}
          </Col>
          <Col>
            <Button
              color="primary"
              onClick={() => {
                if(val == null || val == undefined){
                  toastr.error("Please select image to upload", "Select File");
                  return;
                }
                const formData = new FormData()
                formData.append("image", val)
                formData.append("type", setting.type)
                formData.append("imageLanguage", "en")
                formData.append("category",selectedValue)
                onAction(formData, setting._id)
                
              }}
            >
              Save
            </Button>
          </Col>
        </div>
        <div className="row">
          <Label className="col-sm-2 col-form-label">ImageAr</Label>
          <Col sm={6}>
            <AvForm>
              <input
                type="file"
                onChange={e => {
                  setVal2(e.target.files[0])
                }}
                className="form-control mt-2"
                id="horizontal-firstname-Input"
                placeholder="Image"
                name="image"
                aria-errormessage="Please Upload Image"
                ref={ref2}
              />
            </AvForm>
            {setting.objValue?.arUrl && (
              <img src={setting.objValue?.arUrl} style={{ maxWidth: "5rem" }} />
            )}
          </Col>
          <Col>
            <Button
              color="primary"
              className="mt-2"
              onClick={() => {
                if(val2 == null || val2 == undefined){
                  toastr.error("Please select image to upload", "Select File");
                  return;
                }
                const formData = new FormData()
                formData.append("image", val2)
                formData.append("type", setting.type)
                formData.append("imageLanguage", "ar")
                formData.append("category",selectedValue)
                onAction(formData, setting._id)
              }}
            >
              Save
            </Button>
          </Col>
        </div>
        <div className="row align-items-center mt-3">
       <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
          <Col sm={6}>
          <Dropdown onSelect={handleSelect} direction={"down"}>
      <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
           {selectedValue}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="System">System</Dropdown.Item>
        <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </Col>
        </div>
      </CardBody>
    </Card>
  )
}

const OnlinePackageBanner = ({ setting, onAction ,activeTab, updatePermission}) => {
  const [val, setVal] = React.useState(null)
  const [selectedValue, setSelectedValue] = React.useState(setting?.category || "System");
  const handleSelect = (value) => {
    setSelectedValue(value);
    onAction({category:value}, setting._id)
  }

  const ref = useRef();
  useEffect(() => {
    ref.current.value = null;
    setVal(null);
  }, [setting])

  return (
    <Card>
      <CardBody>
        <CardTitle>{setting.type}</CardTitle>
        <div className="row">
          <Label className="col-sm-2 col-form-label">Online Package Banner</Label>
          <Col sm={6}>
            <AvForm>
              <input
                disabled={!updatePermission}
                type="file"
                onChange={e => {
                  setVal(e.target.files[0])
                }}
                className="form-control"
                id="horizontal-firstname-Input"
                placeholder="Image"
                name="image"
                aria-errormessage="Please Upload Image"
                ref={ref}
              />
            </AvForm>
            {setting.objValue?.onlinePackageBanner && (
              <img src={setting.objValue?.onlinePackageBanner} style={{ maxWidth: "5rem" }} />
            )}
          </Col>
          {
            updatePermission ? 
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  if(val == null || val == undefined){
                    toastr.error("Please select image to upload", "Select File");
                    return;
                  }
                  const formData = new FormData()
                  formData.append("image", val)
                  formData.append("type", setting.type)
                  formData.append("category", selectedValue)
                  onAction(formData, setting._id)
                }}
              >
                Save
              </Button>
            </Col>
            :
            null
          }
        </div>
        <div className="row align-items-center mt-3">
       <Label className="col-sm-2 col-form-label">Category : { activeTab == "1" ? "System" :"Operation "}</Label>
          {
            updatePermission ?
            <Col sm={6}>
            <Dropdown onSelect={handleSelect} direction={"down"}>
        <Dropdown.Toggle variant="success" id="dropdown-basic" title={selectedValue}>
            {selectedValue}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="System">System</Dropdown.Item>
          <Dropdown.Item eventKey="Operation">Operation</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
            </Col>
            :
            null
          }
        </div>
      </CardBody>
    </Card>
  )
}


export default React.memo(SettingRow)
